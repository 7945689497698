import { Component, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';
import { CatalogsLoader } from '@shared/helpers/CatalogsLoader';
import { DocumentTypeDto, MaritalStatusDto, NaturalPersonDto, NuptialsDto, SpouseDto } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'app-marital',
  templateUrl: './marital.component.html',
  styleUrls: ['./marital.component.css']
})
export class MaritalComponent extends AppComponentBase implements OnInit, OnChanges {
  @Input() naturalPerson: NaturalPersonDto = new NaturalPersonDto();

  @Input() maritalDocumentRequired: boolean = false;
  @Input() documentTypes: DocumentTypeDto[];

  @Output() naturalPersonChange: EventEmitter<NaturalPersonDto> = new EventEmitter<NaturalPersonDto>();

  showNuptialsInfo: boolean = false;
  maritalStatuses: MaritalStatusDto[] = [];
  nuptials: NuptialsDto[] = [];

  statusSingleId: number;

  constructor(injector: Injector,
    private _catalogsLoader: CatalogsLoader) {
    super(injector);
  }
  ngOnInit(): void {
    this._catalogsLoader
    .loadNuptials()
    .subscribe(x => {
      this.nuptials = x;
      this.nuptials.sort((a,b) => a.id - b.id);
    });
    this._catalogsLoader
    .loadMaritalStatuses()
    .subscribe(x => {
      this.maritalStatuses = x;
      let maritalStatusSingle = this.maritalStatuses.find(x => x.id == 1);
      this.statusSingleId = maritalStatusSingle.id;
      this.maritalStatusChange();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.naturalPersonChange.emit(this.naturalPerson);
  }

  maritalStatusChange(): void {
    if (this.naturalPerson.maritalStatusId != undefined && this.naturalPerson.maritalStatusId != this.statusSingleId) {
      if (!this.naturalPerson.spouse) {
        this.naturalPerson.spouse = new SpouseDto();
      }
      this.showNuptialsInfo = true;
    } else {
      this.naturalPerson.spouse = undefined;
      this.naturalPerson.nuptialsId = undefined;
      this.showNuptialsInfo = false;
    }
  }
}
