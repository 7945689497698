import { Attribute, Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[validateInList][ngModel],[validateInList][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OptionInListValidator),
      multi: true
    }
  ]
})
export class OptionInListValidator implements Validator {
  @Input('validateInListOptions') options: any[] = [];

  validate(control: AbstractControl): ValidationErrors | null {
    const controlValue = control.value;

    if (!controlValue || !this.options) {
      return null; // If there is no control value, options, or key, consider it valid
    }

    const isValid = this.options.some(option => option.localizationKeyName === controlValue);

    if (!isValid) {
      return { validateInList: true };
    }

    return null;
  }
}
