import { Injector, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinElements',
  pure: false
})
export class JoinPipe implements PipeTransform {

    constructor(injector: Injector) {
    }

    transform(items: any[]): any {
        if (!items) {
            return items;
        }
        var result = items.join(", ");

        return result;
    }
}