import { Injector, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findElements',
  pure: false
})
export class FindElementsPipe implements PipeTransform {

    constructor(injector: Injector) {
    }

    transform(items: any[], callback: any, args: any[]): any {
        if (!items || !callback) {
            return items;
        }
        var result = items.filter(callback, args);

        return result;
    }
}