<select
    class="form-control"
    name="pageSize"
    id="pageSize"
    (change)="onChange($event.target.value)"
>
    <option
        *ngFor="let size of pageSizeOptions"
        [value]="size | localize"
    >
        {{ size | localize }}
    </option>
</select>