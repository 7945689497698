import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-pagination-page-size',
  templateUrl: './pagination-page-size.component.html',
  styleUrls: ['./pagination-page-size.component.css']
})
export class PaginationPageSizeComponent {

  @Output() pageSizeChange: EventEmitter<number> = new EventEmitter<number>();

  pageSizeOptions: number[]= [10,20,50,100];

  constructor() { }

  onChange(newValue: number) {
    this.pageSizeChange.emit(newValue);
  }
}
