import { Injector, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'companyRoleIdFilter',
  pure: false
})
export class CompanyRoleIdFilterPipe implements PipeTransform {

    constructor(injector: Injector) {
    }

    transform(items: any[], filter: any): any {
        if (!items || !filter) {
            return items;
        }

        return items.filter(item => item.companyRoleId == filter.id);
    }
}