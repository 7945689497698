import { Component, Output, EventEmitter, Input, OnChanges, SimpleChanges, Injector, ViewChild, OnInit } from '@angular/core';
import { ContactVisible } from '@app/persons/validations/natural-person-validation';
import { ContactPreferencesEnum } from '@shared/AppEnums';
import { AppComponentBase } from '@shared/app-component-base';
import { ContactDto } from '@shared/service-proxies/service-proxies';
import { AbpValidationError } from '../validation/abp-validation.api';
import { NgForm } from '@angular/forms';
import { SelectableItem } from '@shared/classes/selectable-item';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent extends AppComponentBase implements OnInit, OnChanges {
  @ViewChild('contactForm', { static: false }) form: NgForm;

  @Input() contact: ContactDto = new ContactDto();
  @Input() isViewMode: boolean = false;
  @Input() hideEmail: boolean = false;
  @Input() contactVisible: ContactVisible | undefined = undefined;

  @Output() contactChange: EventEmitter<ContactDto> = new EventEmitter<ContactDto>();

  // ContactPreference
  contactPreferenceSearchField: string;
  contactPreferences: SelectableItem[]=[];
  keysContactPreferences = [];
  contactPreferencesEnum = ContactPreferencesEnum;

  contactValidationErrors: Partial<AbpValidationError>[] = [
    {
      name: 'validateNotEqual',
      localizationKey:
        'MainPhoneNumberMustBeDifferentThanAlternatePhoneNumber',
    },
    {
      name: 'validateContactPreference',
      localizationKey:
        'PreferredCannotBeEmpty',
    },
  ];
  emailValidationErrors: Partial<AbpValidationError>[] = [
    {
      name: 'pattern',
      localizationKey: 'InvalidEmailFormat',
      propertyKey: 'requiredPattern',
    },
  ];

  constructor(injector: Injector) {
    super(injector);
    this.keysContactPreferences = Object.values(this.contactPreferencesEnum).filter(isNaN) as string[];
  }

  ngOnInit(): void {
    // ContactPreference
    this.contactPreferences = [];
    this.keysContactPreferences.forEach( (key, index) => {
      let item = new SelectableItem();
      item.id = index;
      item.value = this.contactPreferencesEnum[key];
      item.localizationKeyName = this.l(key);
      this.contactPreferences.push(item);
    });
    let selectedContactPreference = this.contact?.contactPreferences != undefined ? this.contactPreferences.find(x => x.value == this.contact?.contactPreferences) : undefined;
    this.contactPreferenceSearchField = selectedContactPreference ? selectedContactPreference.localizationKeyName : '';
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.contactChange.emit(this.contact);
  }

  showEmail(): boolean {
    return !this.hideEmail && (this.contactVisible == undefined || this.contactVisible?.emailAddress);
  }

  showMainPhone(): boolean {
    return this.contactVisible == undefined || this.contactVisible?.mainPhoneNumber;
  }

  showAlternatePhone(): boolean {
    return this.contactVisible == undefined || this.contactVisible?.alternatePhoneNumber;
  }

  // If alternate phone number is available we need to select preference.
  showContactPreferences(): boolean {
    return this.contactVisible == undefined || this.contactVisible?.alternatePhoneNumber;
  }

  onEmailAddressChange() {
    this.form.control.get('contactPreferences').updateValueAndValidity();
  }

  onMainPhoneNumberChange() {
    this.form.control.get('alternatePhoneNumber').updateValueAndValidity();
    this.form.control.get('contactPreferences').updateValueAndValidity();
  }

  onAlternatePhoneNumberChange() {
    this.form.control.get('contactPreferences').updateValueAndValidity();
  }

  onContactPreferenceSelected(event: TypeaheadMatch): void {
    const selectedOption = event.value;
    let selectedItem = this.contactPreferences.find(x => x.localizationKeyName == selectedOption);
    this.contact.contactPreferences = selectedItem ? selectedItem.value : undefined;
  }

  onContactPreferenceFocusOut() {
    if (this.contactPreferenceSearchField == '') this.contact.contactPreferences = undefined;
  }

  getContactPreferenceName(contact: ContactDto | undefined): string {
    if (contact && contact.contactPreferences != undefined) {
      let item = this.contactPreferences.find(x => x.value == contact.contactPreferences);
      return item ? this.l(item.localizationKeyName) : '';
    }
    return '';
  }
}
