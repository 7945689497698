import { Injector, Pipe, PipeTransform } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';
import { LibraryConfigurationFileTypeEnum } from '@shared/AppEnums';

@Pipe({
    name: 'fileTypePipe',
})
export class FileTypePipe extends AppComponentBase implements PipeTransform {

    constructor(injector: Injector) {
        super(injector);
    }

    transform(
        value: LibraryConfigurationFileTypeEnum,
    ): boolean {
        return value != LibraryConfigurationFileTypeEnum.File;
    }
}