import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, searchText: string): SafeHtml {
    if (!searchText) {
      return value;
    }
    const re = new RegExp(searchText, 'gi'); // 'gi' for case-insensitive global search
    const match = value.match(re);
    if (!match) {
      return value;
    }
    const highlightedValue = value.replace(re, `<mark>${match[0]}</mark>`);
    return this.sanitizer.bypassSecurityTrustHtml(highlightedValue);
  }
}