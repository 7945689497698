import { Component, Injector, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';
import { BreadcrumbItem } from '@shared/classes/breadcrumb-item';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css']
})
export class BreadcrumbsComponent extends AppComponentBase implements OnInit {
  breadcrumbItems: BreadcrumbItem[] = [];
  icon: string = '';

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.breadcrumbsService.breadcrumbItems.subscribe({
      next: (results) =>{
        this.breadcrumbItems = results;
        let itemWithIcon = this.breadcrumbItems.find(x => x.icon.length > 0);
        this.icon = itemWithIcon ? itemWithIcon.icon : 'home';
      }
    });
  }

}
