import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';
import { NotificationCountUpdatedService } from '@shared/helpers/notification-count-updated.service';
import { BaseMessageNotificationDataDto, NotificationServiceProxy } from '@shared/service-proxies/service-proxies';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent extends AppComponentBase implements OnInit {
  notificationsList: BaseMessageNotificationDataDto[];

  constructor(
    injector: Injector,
    private _notificationService: NotificationServiceProxy,
    private _notificationCountUpdatedService: NotificationCountUpdatedService,
    private cdr: ChangeDetectorRef
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this._notificationService
    .getNotificationsInfo()
    .subscribe((result: BaseMessageNotificationDataDto[]) => {
      this.notificationsList = result;
      this.cdr.detectChanges();
    });
  }

  setNotificationAsRead(notificationId: string): void {
    this._notificationService
    .setNotificationAsRead(notificationId)
    .subscribe({
      next: () => {
        this.notificationsList = [];
        this._notificationCountUpdatedService.setNewNotificationRefresh(true);
        this.ngOnInit();
      }
    });
  }

  setNotificationAsArchived(notificationId: string): void {
    this._notificationService
    .setNotificationAsArchived(notificationId)
    .subscribe({
      next: () => {
        this.notificationsList = [];
        this._notificationCountUpdatedService.setNewNotificationRefresh(true);
        this.ngOnInit();
      }
    });
  }

  setAllNotificationsAsRead(): void {
    this._notificationService
    .setAllNotificationsAsArchivated()
    .subscribe({
      next: () => {
        this.notificationsList = [];
        this._notificationCountUpdatedService.setNewNotificationRefresh(true);
        this.cdr.detectChanges();
      }
    });
  }
}
