import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common'

@Pipe({
    name: 'customCurrency'
})
export class CustomCurrencyPipe implements PipeTransform {

    constructor(private cp: CurrencyPipe) {
    }

    transform(value: number, currencyCode: string): string {
        let result = this.cp.transform(value, '', '');
        return `${currencyCode} ${result}`;
    }
}
