import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class LibraryService {
    data: object = {};
    sectionData: object = {};
    sectionUnboundData: object = {};

    private dataSub = new BehaviorSubject(this.data);
    private selectionData = new BehaviorSubject(this.sectionData);
    private selectionUnboundData = new BehaviorSubject(this.sectionUnboundData);


    currentSubtitleData = this.dataSub.asObservable();
    sectionSelectedData = this.selectionData.asObservable();
    sectionSelectedUnboundData = this.selectionUnboundData.asObservable();

    constructor() { }

    changeDataSub(newData: object) {
        this.dataSub.next(newData);
    }

    changeSectionOption(newData: object) {
        this.selectionData.next(newData);
    }

    changeSectionOptionUnbound(newData: object) {
        this.selectionUnboundData.next(newData);
    }
}