import { BehaviorSubject } from 'rxjs';

export class ModalStatusService {
  private modalMinimizedStatusMap: Map<string, boolean> = new Map<string, boolean>();
  private minimizedStatusSubject: BehaviorSubject<{ id: string; status: boolean }> =
   new BehaviorSubject<{ id: string; status: boolean }>({ id: '', status: false });

  // Get the observable for other components to subscribe to
  getMinimizedStatusObservable() {
    return this.minimizedStatusSubject.asObservable();
  }

  // Get the status of a specific modal
  getMinimizedStatus(id: string): boolean | undefined {
    return this.modalMinimizedStatusMap.get(id);
  }

  // Add or update the status of a modal
  setMinimizedStatus(id: string, status: boolean, fireEvent: boolean = true): void {
    this.modalMinimizedStatusMap.set(id, status);
    if(fireEvent) {
      this.minimizedStatusSubject.next({ id, status });
    }
  }

  // Remove the status of a modal
  removeMinimizedStatus(id: string, fireEvent: boolean = true): void {
    this.modalMinimizedStatusMap.delete(id);
    if(fireEvent) {
      this.minimizedStatusSubject.next({ id, status: false });
    }
  }
}