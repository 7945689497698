import { Injector, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {

    constructor(injector: Injector) {
    }

    transform(items: any[], callback, search?: string): any {
        if (!items || !callback) {
            return items;
        }

        if(search && search.length > 0) {
            var result = items.filter(x => callback(x, search));
        } else {
            var result = items.filter(x => callback(x));
        }

        return result;
    }
}