export class AppConsts {

    static readonly tenancyNamePlaceHolderInUrl = '{TENANCY_NAME}';

    static remoteServiceBaseUrl: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static defaultSessionExpirationInSeconds: number = 1800; // 30 minutes

    static domain: string = 'lc.uy';

    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'LC'
    };

    static readonly authorization = {
        encryptedAuthTokenName: 'enc_auth_token'
    };

    static Styles = {
        HideColumnMobile: "d-none d-lg-table-cell",
        ShowColumnMobile: "d-table-cell d-sm-none",

        HideMobile: "d-none d-lg-inline",
        ShowMobile: "d-inline d-sm-none",

        FormLabelClass: "col-md-4 col-4 col-form-label",
        FormDataClass: "col-md-8 col-8",

        ButtonIcon: "fas ml-1",

        DateInlineStyle: "width: 90px",
        CategoryInlineStyle: "width: 250px",
        SelectInlineStyle: "width: 45px",
    }

    static maxResultsCount = 2147483647;
    static pageSize = 10;

    static colors: string[] = ['bg-white','bg-primary','bg-secondary','bg-info','bg-success','bg-warning','bg-danger'];

    static MaritalStatusSingle: string = 'Single';

    static Permissions = {
        RelationsDelete: 'Relations.Delete',
        SocietiesDelete: 'Companies.Delete'
    }

    static adminRole: string = 'Admin';

    static defaultGuid: string = '00000000-0000-0000-0000-000000000000';

    static occupationOther: string = 'Other';
    static otherWorkActivity: string = 'NewActivity';
    static eraseOption: string = 'Erase';

    static singleStatus: string = "Single";
    static widowedStatus: string = "Widowed";

    static maxFileSizeInBytes: number = 20971520;

    static directorPositionExcludeList: string[] = ['Actionist','Attorney','FinalBeneficiary','GeneralAttorney','Partner','SpecialAttorney'];
}
