import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[validateSpecialCharacters][ngModel],[validateSpecialCharacters][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SpecialCharactersValidator),
      multi: true
    }
  ]
})
export class SpecialCharactersValidator implements Validator {
  constructor() {}

  validate(control: AbstractControl): ValidationErrors | null {
    if(!control.value || control.value == '') { return null; }

    const forbidden = /^(?![\W_]+$).+$/.test(control.value);
      return !forbidden ? { 'validateSpecialCharacters': true } : null;
  }
}
