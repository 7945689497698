import { Injectable } from '@angular/core';
import { AccountServiceProxy, TenantPublicSettingsDto } from '@shared/service-proxies/service-proxies';
import { throwError as _observableThrow, of as _observableOf } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TenantThemeService {

    constructor(private _accountService: AccountServiceProxy) {

    }

    // Set the settings
    public async InitializeTheme(): Promise<TenantPublicSettingsDto> {
        const tenantId = abp.multiTenancy.getTenantIdCookie() !== null &&
        abp.multiTenancy.getTenantIdCookie() !== undefined ?
        abp.multiTenancy.getTenantIdCookie() :
        0;

        const result: TenantPublicSettingsDto =
            <TenantPublicSettingsDto>await this._accountService.getTenantPublicSettings(tenantId).toPromise();

        this.setTheme({
            'primaryColor': result.primaryColor,
            'primaryColorBack': result.primaryColorBack,
            'primaryColorFocus': result.primaryColorFocus,
            'primaryColorLight': result.primaryColorLight,
        });
        return result;
    }

    private setTheme(theme: {}) {
        Object.keys(theme).forEach(k =>
            document.documentElement.style.setProperty(`--${k}`, theme[k])
        );
    }
}