import { Injector, Pipe, PipeTransform } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';

@Pipe({
  name: 'balanceMonth',
  pure: false
})
export class BalanceMonthPipe extends AppComponentBase implements PipeTransform {

    constructor(injector: Injector) {
        super(injector);
    }

    transform(month: number): string {
        let result = '';
        switch(month) {
            case 1: {
                result = '31/01'
                break;
            }
            case 2: {
                result = '28/02'
                break;
            }
            case 3: {
                result = '31/03'
                break;
            }
            case 4: {
                result = '30/04'
                break;
            }
            case 5: {
                result = '31/05'
                break;
            }
            case 6: {
                result = '30/06'
                break;
            }
            case 7: {
                result = '31/07'
                break;
            }
            case 8: {
                result = '31/08'
                break;
            }
            case 9: {
                result = '30/09'
                break;
            }
            case 10: {
                result = '31/10'
                break;
            }
            case 11: {
                result = '30/11'
                break;
            }
            case 12: {
                result = '31/12'
                break;
            }
            default: {
               break;
            }
         }
        return result;
    }
}