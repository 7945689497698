import { BehaviorSubject } from 'rxjs';

export class SharedStatusService {
  private profilePhotoString: string = "";
  profilePhoto: BehaviorSubject<string>;

  private logoPhotoString: string = "";
  logoPhoto: BehaviorSubject<string>;

  private userNameString: string = "";
  userName: BehaviorSubject<string>;

  constructor() {
    this.profilePhoto = new BehaviorSubject(this.profilePhotoString);
    this.logoPhoto = new BehaviorSubject(this.logoPhotoString);
    this.userName = new BehaviorSubject(this.userNameString);
  }

  setNewProfilePhoto(newValue: string) {
    this.profilePhoto.next(newValue);
  }

  setNewLogoPhoto(newValue: string) {
    this.logoPhoto.next(newValue);
  }

  setNewUserName(newValue: string) {
    this.userName.next(newValue);
  }
}