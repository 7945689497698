import { Injector, Pipe, PipeTransform } from '@angular/core';
import { PersonCompanyRoleDataDto as ItemDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/app-component-base';

@Pipe({
  name: 'personType',
  pure: false
})
export class PersonTypePipe extends AppComponentBase implements PipeTransform {

    constructor(injector: Injector) {
        super(injector);
    }

    transform(item: ItemDto): string {
        return item.naturalPerson != null ? this.l("NaturalPerson") : this.l("JuridicalPerson");
    }
}