import { Attribute, Directive, Injector, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { ContactPreferencesEnum } from '../AppEnums'
import { AppComponentBase } from '@shared/app-component-base';

@Directive({
  selector: '[validateContactPreference][ngModel],[validateContactPreference][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ContactPreferenceValidator),
      multi: true
    }
  ]
})
export class ContactPreferenceValidator extends AppComponentBase implements Validator {
  constructor(injector: Injector) {super(injector);}

  validate(control: AbstractControl): ValidationErrors | null {
    const emailAddressControl = control.root.get("emailAddress");
    const mainPhoneNumberControl = control.root.get("mainPhoneNumber");
    const alternateNumberControl = control.root.get("alternatePhoneNumber");

    const contactPreferenceControl = control;

    if (!contactPreferenceControl || contactPreferenceControl.value == null) {
      return null;
    }

    switch (contactPreferenceControl.value) {
      case this.l("MainPhoneNumber"): //<number>ContactPreferencesEnum.MainPhoneNumber:
        if (!mainPhoneNumberControl || mainPhoneNumberControl.value == null || mainPhoneNumberControl.value == '') {
          return { validateContactPreference: true };
        }
        break;
      case this.l("AlternatePhoneNumber"): //<number>ContactPreferencesEnum.AlternatePhoneNumber:
        if (!alternateNumberControl || alternateNumberControl.value == null || alternateNumberControl.value == '') {
          return { validateContactPreference: true };
        }
        break;
      case this.l("EmailAddress"): //<number>ContactPreferencesEnum.EmailAddress:
        if (!emailAddressControl || emailAddressControl.value == null || emailAddressControl.value == '') {
          return { validateContactPreference: true };
        }
        break;
    }

    return null;
  }
}
