import { Injector, Pipe, PipeTransform } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';
import { Moment } from 'moment';

@Pipe({
  name: 'dateTime',
  pure: false
})
export class DateTimePipe extends AppComponentBase implements PipeTransform {

    constructor(injector: Injector) {
        super(injector);
    }

    transform(dateTime: Moment): string {
      if (!dateTime || dateTime.toString() === "Invalid date") {
        return "-";
      }
      return dateTime.format(this.getDateInputFormat());
    }
}