import { Injector, Pipe, PipeTransform } from '@angular/core';
import { PersonCompanyRoleDataDto as ItemDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/app-component-base';

@Pipe({
  name: 'personFullName',
  pure: false
})
export class PersonFullNamePipe extends AppComponentBase implements PipeTransform {

    constructor(injector: Injector) {
        super(injector);
    }

    transform(item: ItemDto): string {
        if (!item) {
            return '';
        }

        return item.naturalPerson != null ? item.naturalPerson.names + ' ' + item.naturalPerson.surnames : item.juridicalPerson?.name;
    }
}