import { Attribute, Directive, forwardRef, Injector } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { AppComponentBase } from '@shared/app-component-base';
import * as moment from 'moment';

@Directive({
  selector: '[validateLowerThanCurrent][ngModel],[validateLowerThanCurrent][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LowerThanCurrentValidator),
      multi: true
    }
  ]
})
export class LowerThanCurrentValidator extends AppComponentBase implements Validator {
  constructor(injector: Injector) {
    super(injector);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const dateValue = control.value;

    if (!dateValue) {
      return null;
    }

    const enteredDate = moment(dateValue, this.getDateInputFormat(), true);
    const currentDate = moment();

    return enteredDate.isSameOrAfter(currentDate, 'day') ? { validateLowerThanCurrent: true } : null;
  }
}
