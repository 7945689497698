<div class="modal-header" [ngClass]="{ 'minimized': minimized }" ngxModalDraggable 
     (click)="expandRestoreIfMinimized($event)" (dblclick)="expandRestore($event)">
  <h4 [innerHTML]="minimized ? getHeaderContent() : title.toUpperCase()"></h4>
  <div class="modal-header-controls">
    <button type="button" class="control-button" id="close" (click)="closeClick($event)">
      <i class="fas fa-times"></i>
    </button>
    <button type="button" class="control-button" id="maximize" (click)="expandRestore($event)">
      <i class="fas" [ngClass]="maximized ? 'fa-compress-alt' : 'fa-expand-alt'"></i>
    </button>
    <button type="button" class="control-button" id="minimize" (click)="minimizeRestore($event)">
      <i class="fas" [ngClass]="minimized ? 'fa-arrow-up' : 'fa-minus'"></i>
    </button>
  </div>
</div>
