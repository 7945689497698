<div *ngIf="notificationsList?.length > 0">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-6 mt-1">
          <p>{{ "Notifications" | localize }}</p>
        </div>
        <div class="col-6 text-right">
          <a href="javascript:;" class="btn btn-sm btn-notifications" (click)="setAllNotificationsAsRead()">
            {{ "ArchiveAll" | localize }}
          </a>
        </div>
      </div>
    </div>
  </section>
  <section class="content">
    <div class="container-fluid">
      <div class="card card-title-notifications">
        <div class="row">
          <div class="col-12">
            <p class="d-inline ml-2">{{ "Name" | localize }}</p>
            <p class="d-inline" style="margin-left: 3.2rem;">{{ "Date" | localize }}</p>
            <p class="d-inline" style="margin-left: 2.5rem;">{{ "Notification" | localize }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="content scrolleable">
    <div class="container-fluid">
      <div class="card card-item-notifications" *ngFor="let notification of notificationsList">
        <div class="row">
          <div class="col-12">
            <p class="item-notification-option d-inline ml-2">{{ notification.fullName }}</p>
            <p class="item-notification-option d-inline ml-2">{{ notification.notificationSent | date: getDateInputLowerFormat() }}</p>
            <div class="notification-actions float-right">
              <p class="badge badge-notification item-notification-option d-inline ml-2">{{ notification.templateName | localize }}</p>
              <i class="far fa-bell-slash d-inline item-notification-option notification-unread-archive ml-2 mr-2" 
                  [tooltip]="'MarkAsRead' | localize"
                  (click)="setNotificationAsRead(notification.notificationId)"></i>
              <i class="fas fa-inbox d-inline item-notification-option notification-unread-archive ml-2 mr-2" 
                  [tooltip]="'MarkAsArchived' | localize"
                  (click)="setNotificationAsArchived(notification.notificationId)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div *ngIf="notificationsList?.length == 0">
  <section class="content-header">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 mt-1">
          <p>{{ "DontHaveUnarchivedNotifications" | localize }}</p>
        </div>
      </div>
    </div>
  </section>
</div>