import { Component, Output, EventEmitter, Input,
  OnChanges, SimpleChanges, OnInit, Injector, ViewChild } from '@angular/core';
import { AddressDto,
  CountryDto,
  LocationDto,
} from '@shared/service-proxies/service-proxies';
import { CatalogsLoader } from '@shared/helpers/CatalogsLoader';
import { AppComponentBase } from '@shared/app-component-base';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css']
})
export class AddressComponent extends AppComponentBase implements OnInit, OnChanges {
  shouldDisableMap: { [key: string]: boolean } = {};

  @Input() address: AddressDto = new AddressDto();
  @Input() addressOriginal: AddressDto | undefined;
  @Input() editMode: boolean = true;
  @Input() isViewMode: boolean = false;
  @Input() isCreating: boolean = false;

  countries: CountryDto[]=[];
  locations: LocationDto[]=[];
  uruguayCountryId: number;

  // Country
  countrySearchField: string;
  // Location
  locationSearchField: string;

  @Output() addressChange: EventEmitter<AddressDto> = new EventEmitter<AddressDto>();

  constructor(
    injector: Injector,
    private _catalogsLoader: CatalogsLoader) {
      super(injector);
    }

    ngOnInit(): void {

      if (this.address) {
        this.handleAddressDisableMap(this.address);
      }
  
      this._catalogsLoader
      .loadCountries()
      .subscribe(x => {
        this.countries = x;
        if (this.uruguayCountryId == null) {
          this.uruguayCountryId = this.countries.find(x => x.isoAlpha2Code == 'UY').id;
        }
        let selectedCountry = this.address.countryId ? this.countries.find(x => x.id == this.address.countryId) : undefined;
        this.countrySearchField = selectedCountry ? selectedCountry.localizationKeyName : '';
      });
      if (this.address.countryId != undefined) {
        this._catalogsLoader
        .loadLocations(this.address.countryId)
        .subscribe(x => {
          this.locations = x;
          let selectedLocation = this.address.locationId ? this.locations.find(x => x.id == this.address.locationId) : undefined;
          this.locationSearchField = selectedLocation ? selectedLocation.localizationKeyName : '';
        });
      }
    }

    onCountrySelected(event: TypeaheadMatch): void {
      const selectedOption = event.value;
      let selectedCountry = this.countries.find(x => x.localizationKeyName == selectedOption);
      this.address.countryId = selectedCountry ? selectedCountry.id : undefined;
      this.changeCountry();
      this.addressChange.emit(this.address);
    }

    onCountryFocusOut() {
      if (this.countrySearchField == '') {
        this.address.countryId = undefined;
        this.changeCountry();
        this.addressChange.emit(this.address);
      }
    }

    onLocationSelected(event: TypeaheadMatch): void {
      const selectedOption = event.value;
      let selectedLocation = this.locations.find(x => x.localizationKeyName == selectedOption);
      this.address.locationId = selectedLocation ? selectedLocation.id : undefined;
      this.addressChange.emit(this.address);
    }

    onLocationFocusOut() {
      if (this.locationSearchField == '') {
        this.address.locationId = undefined;
        this.addressChange.emit(this.address);
      }
    }

    getCountryName(address: AddressDto | undefined): string {
      if (address && address.countryId) {
        let country = this.countries.find(x => x.id == address.countryId);
        return country ? this.l(country.localizationKeyName) : '';
      }
      return '';
    }

    getLocationName(address: AddressDto | undefined): string {
      if (address && address.locationId && this.locations.length > 0) {
        let location = this.locations.find(x => x.id == address.locationId);
        return location ? this.l(location.localizationKeyName) : '';
      }
      return '';
    }

    changeCountry() {
      if (this.address.countryId != undefined) {
        this._catalogsLoader
        .loadLocations(this.address.countryId)
        .subscribe(x => {
          this.locations = x;
          this.address.locationId = null;
          this.address.location = null;
          this.locationSearchField = '';
        });
      }
    }

    ngOnChanges(changes: SimpleChanges): void {
      // Not emit changes on model loaded. If a change event was triggered by an edit action then emitChanges() must be capable of handle it.
      // this.addressChange.emit(this.address);
    }

    emitChanges(){
      this.addressChange.emit(this.address);
    }

    set(countryId: number, locationId: number) {
      this.address.countryId = countryId;
      this.address.locationId = locationId;
      let selectedCountry = this.address.countryId ? this.countries.find(x => x.id == this.address.countryId) : undefined;
      this.countrySearchField = selectedCountry ? selectedCountry.localizationKeyName : '';
      if (this.address.countryId != undefined) {
        this._catalogsLoader
        .loadLocations(this.address.countryId)
        .subscribe(x => {
          this.locations = x;
          let selectedLocation = this.address.locationId ? this.locations.find(x => x.id == this.address.locationId) : undefined;
          this.locationSearchField = selectedLocation ? selectedLocation.localizationKeyName : '';
        });
      }
    }

    clear() {
      this.address.countryId = null;
      this.address.country = null;
      this.countrySearchField = '';
      this.locations = [];
      this.address.locationId = null;
      this.address.location = null;
      this.locationSearchField = '';
    }

    shouldShowEmptyField(value: any): boolean {
      return !this.isViewMode && !this.isCreating && (value === null || value === undefined || value === '');
    }

    shouldDisableValue(key: string): boolean | undefined {
      return this.shouldDisableMap[key];
    }

    handleAddressDisableMap(address: AddressDto) {
      this.shouldDisableMap['id'] = address.id != undefined && address.id > 0;
      this.shouldDisableMap['country'] = address.countryId != undefined && address.countryId > 0;
      this.shouldDisableMap['location'] = address.locationId != undefined && address.locationId > 0;
      this.shouldDisableMap['street'] = address.street != undefined && address.street !== '';
      this.shouldDisableMap['number'] = address.number != undefined && address.number !== '';
      this.shouldDisableMap['apartment'] = address.apartment != undefined && address.apartment != null && address.apartment !== '';
      this.shouldDisableMap['locationText'] = address.locationText != undefined && address.locationText != null && address.locationText !== '';
      this.shouldDisableMap['others'] = address.others != undefined && address.others !== '';
    }

}
