import { Component, Injector, Input, OnInit } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.css']
})
export class TitleComponent extends AppComponentBase implements OnInit {
  @Input() title: string= '';
  @Input() titleParams: any[] = [];
  @Input() icon: string= '';
  @Input() subTitle: string= null;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    this.title = this.l(this.title, this.titleParams);
  }
}