import { Injector, Pipe, PipeTransform } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';
import * as moment from 'moment';
import { Moment } from 'moment';

@Pipe({
  name: 'dateLessThanCurrent',
  pure: false
})
export class DateLessThanCurrentPipe extends AppComponentBase implements PipeTransform {

    constructor(injector: Injector) {
        super(injector);
    }

    transform(dateTime: Moment): boolean {
      const currentDate = moment();
      const enteredDate = moment(dateTime);
      
      if (!dateTime || dateTime.toString() === "Invalid date") {
        return false;
      }

      if (enteredDate.isSameOrBefore(currentDate, 'day')) {
        return true;
      }

      return false;
    }
}