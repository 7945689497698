import { BehaviorSubject } from 'rxjs';

export class NotificationCountUpdatedService {
  private notificationCountRefresh: boolean = false;
  notification: BehaviorSubject<boolean>;

  constructor() {
    this.notification = new BehaviorSubject(this.notificationCountRefresh);
  }

  setNewNotificationRefresh(newValue: boolean) {
    this.notification.next(newValue);
  }
}