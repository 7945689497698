import { Attribute, Directive, Injector, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { AppComponentBase } from '@shared/app-component-base';
import * as moment from 'moment';

@Directive({
  selector: '[validateLowerThan][ngModel],[validateLowerThan][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LowerThanValidator),
      multi: true
    }
  ]
})
export class LowerThanValidator extends AppComponentBase implements Validator {
  constructor(injector: Injector, @Attribute('validateLowerThan') public endDate: string) {
    super(injector);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const startDateControl = control;
    const endDateControl = control.root.get(this.endDate);

    if (!startDateControl || !endDateControl) {
      return null;
    }

    const startDate = moment(startDateControl.value, this.getDateInputFormat(), true);
    const endDate = moment(endDateControl.value, this.getDateInputFormat(), true);

    return startDate.isSameOrAfter(endDate, 'day') ? { validateLowerThan: true } : null;
  }
}
