<div class="form-group row">
  <label
    [ngClass]="styles.FormLabelClass"
    class="col-form-label"
    for="maritalStatuses"
  >
    {{ "MaritalStatus" | localize }}
  </label>
  <div [ngClass]="styles.FormDataClass">
    <select
      (change)="maritalStatusChange()"
      class="form-control"
      name="maritalStatuses"
      id="maritalStatuses"
      [(ngModel)]="naturalPerson.maritalStatusId"
      #maritalStatusModel="ngModel"
      #maritalStatusModelEl
    >
      <option
        *ngFor="let maritalStatus of maritalStatuses"
        [value]="maritalStatus.id"
      >
        {{ maritalStatus.localizationKeyName | localize }}
      </option>
    </select>
    <abp-validation-summary
      [control]="maritalStatusModel"
      [controlEl]="maritalStatusModelEl"
    ></abp-validation-summary>
  </div>
</div>
<div class="form-group row" *ngIf="showNuptialsInfo">
  <label
    [ngClass]="styles.FormLabelClass"
    class="col-form-label"
    for="nuptials"
  >
    {{ "Nuptials" | localize }}
  </label>
  <div [ngClass]="styles.FormDataClass">
    <select
      class="form-control"
      name="nuptials"
      id="nuptials"
      [(ngModel)]="naturalPerson.nuptialsId"
      #nuptialModel="ngModel"
      #nuptialModelEl
    >
      <option *ngFor="let nuptial of nuptials" [value]="nuptial.id">
        {{ nuptial.localizationKeyName | localize }}
      </option>
    </select>
    <abp-validation-summary
      [control]="nuptialModel"
      [controlEl]="nuptialModelEl"
    ></abp-validation-summary>
  </div>
</div>
<div class="form-group row" *ngIf="showNuptialsInfo">
  <label
    [ngClass]="styles.FormLabelClass"
    class="col-form-label"
    for="spouseNames"
  >
    {{ "SpouseNames" | localize }}
  </label>
  <div [ngClass]="styles.FormDataClass">
    <input
      type="text"
      class="form-control"
      name="spouseNames"
      maxlength="64"
      [(ngModel)]="naturalPerson.spouse.names"
      #spouseNamesModel="ngModel"
      #spouseNamesEl
    />
    <abp-validation-summary
      [control]="spouseNamesModel"
      [controlEl]="spouseNamesEl"
    ></abp-validation-summary>
  </div>
</div>
<div class="form-group row" *ngIf="showNuptialsInfo">
  <label
    [ngClass]="styles.FormLabelClass"
    class="col-form-label"
    for="spouseSurnames"
  >
    {{ "SpouseSurnames" | localize }}
  </label>
  <div [ngClass]="styles.FormDataClass">
    <input
      type="text"
      class="form-control"
      name="spouseSurnames"
      maxlength="64"
      [(ngModel)]="naturalPerson.spouse.surnames"
      #spouseSurnamesModel="ngModel"
      #spouseSurnamesEl
    />
    <abp-validation-summary
      [control]="spouseSurnamesModel"
      [controlEl]="spouseSurnamesEl"
    ></abp-validation-summary>
  </div>
</div>
<div class="form-group row" *ngIf="showNuptialsInfo && maritalDocumentRequired">
  <label
    [ngClass]="styles.FormLabelClass"
    class="col-form-label"
    for="documentTypeDD"
  >
    {{ "DocumentType" | localize }}
  </label>
  <div [ngClass]="styles.FormDataClass">
    <select
      class="form-control"
      name="documentTypeDD"
      id="documentTypeDD"
      [(ngModel)]="naturalPerson.spouse.identificationDocument.documentTypeId"
    >
      <option *ngFor="let docType of documentTypes" [value]="docType.id">
        {{ docType.localizationKeyName | localize }}
      </option>
    </select>
  </div>
</div>
<div class="form-group row" *ngIf="showNuptialsInfo && maritalDocumentRequired">
  <div class="input-group">
    <label
      [ngClass]="styles.FormLabelClass"
      class="col-form-label"
      for="documentNumber"
    >
      {{ "DocumentNumber" | localize }}
    </label>
    <div [ngClass]="styles.FormDataClass">
      <input
        type="text"
        class="form-control"
        name="documentNumber"
        [(ngModel)]="naturalPerson.spouse.identificationDocument.documentNumber"
        #documentNumberModel="ngModel"
        #documentNumberEl
      />
      <abp-validation-summary
        [control]="documentNumberModel"
        [controlEl]="documentNumberEl"
      ></abp-validation-summary>
    </div>
  </div>
</div>
