import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector:
    // tslint:disable-next-line:directive-selector
    '[validateNotEqual][formControlName],[validateNotEqual][formControl],[validateNotEqual][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NotEqualValidator),
      multi: true
    }
  ]
})
export class NotEqualValidator implements Validator {
  constructor(@Attribute('validateNotEqual') public validateNotEqual: string) {}

  validate(control: AbstractControl): ValidationErrors | null {
    // self control
    const seflControl = control;
    // second control
    const control2 = control.root.get(this.validateNotEqual);

    if (!seflControl || !control2) {
      return null;
    }

    // value not equal&
    if (seflControl.value === control2.value) {
      return { validateNotEqual: true };
    }

    return null;
  }
}
