import { Injector, ElementRef, ChangeDetectorRef } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import {
  LocalizationService,
  PermissionCheckerService,
  FeatureCheckerService,
  NotifyService,
  SettingService,
  MessageService,
  AbpMultiTenancyService
} from 'abp-ng2-module';

import { AppSessionService } from '@shared/session/app-session.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from './helpers/breadcrumbs.service';
import { BreadcrumbItem } from './classes/breadcrumb-item';
import { LibraryService } from './helpers/library.service';
import { getLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { ptBrLocale } from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

export abstract class AppComponentBase {

  AppConsts = AppConsts;

  localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
  icon: string = '';
  title: string = '';

  localization: LocalizationService;
  permission: PermissionCheckerService;
  feature: FeatureCheckerService;
  notify: NotifyService;
  setting: SettingService;
  message: MessageService;
  multiTenancy: AbpMultiTenancyService;
  appSession: AppSessionService;
  elementRef: ElementRef;
  router: Router;
  activatedRoute: ActivatedRoute;
  cd: ChangeDetectorRef;
  breadcrumbsService: BreadcrumbsService;
  libraryService: LibraryService;
  bsLocaleService: BsLocaleService;
  bsDatepickerConfig: BsDatepickerConfig;

  // Consts
  styles = AppConsts.Styles;
  hideMobile = AppConsts.Styles.HideColumnMobile;
  showMobile = AppConsts.Styles.ShowColumnMobile;
  hideMobileGeneral = AppConsts.Styles.HideMobile;
  showMobileGeneral = AppConsts.Styles.ShowMobile;

  constructor(injector: Injector) {
    this.localization = injector.get(LocalizationService);
    this.permission = injector.get(PermissionCheckerService);
    this.feature = injector.get(FeatureCheckerService);
    this.notify = injector.get(NotifyService);
    this.setting = injector.get(SettingService);
    this.message = injector.get(MessageService);
    this.multiTenancy = injector.get(AbpMultiTenancyService);
    this.appSession = injector.get(AppSessionService);
    this.elementRef = injector.get(ElementRef);
    this.router = injector.get(Router);
    this.activatedRoute = injector.get(ActivatedRoute);
    this.cd = injector.get(ChangeDetectorRef);
    this.breadcrumbsService = injector.get(BreadcrumbsService);
    this.libraryService = injector.get(LibraryService);
    this.bsLocaleService = injector.get(BsLocaleService);
    this.bsDatepickerConfig = injector.get(BsDatepickerConfig);

    this.bsDatepickerConfig.isAnimated = true;
    this.bsDatepickerConfig.customTodayClass = "today-class"

    switch (this.localization.currentLanguage.name) {
      case 'es':
        defineLocale('es', esLocale);
        this.bsLocaleService.use('es');
        break;
      case 'pt':
        defineLocale('pt', ptBrLocale);
        this.bsLocaleService.use('pt');
        break;
      case 'en':
        this.bsLocaleService.use('en');
        break;
      default:
        this.bsLocaleService.use('en');
        break;
    }

    const locale = getLocale();
    locale.invalidDate = this.l("InvalidDate");
  }

  getDateInputFormat(): string {
    return this.l('DateInputFormat');
  }

  getDateInputLowerFormat(): string {
    return this.l('DateInputFormatLower');
  }

  setBreadcrumbItems(breadcrumbItems: BreadcrumbItem[]) {
    this.breadcrumbsService.setNewBreadcrumbsItems(breadcrumbItems);
  }

  l(key: string, ...args: any[]): string {
    let localizedText = this.localization.localize(key, this.localizationSourceName);

    if (!localizedText) {
      localizedText = key;
    }

    if (!args || !args.length) {
      return localizedText;
    }

    args.unshift(localizedText);
    return abp.utils.formatString.apply(this, args);
  }

  isGranted(permissionName: string): boolean {
    return this.permission.isGranted(permissionName);
  }

  hasOnePermissionGranted(permissionsNameList: string[]): boolean {
    let permitted = false;
    permissionsNameList.forEach(element => {
      if (this.permission.isGranted(element)) {
        permitted = true;
      }
    });
    return permitted;
  }

  isTenant(): boolean {
    return this.appSession.tenantId > 0;
  }

  isLoggedIn(): boolean {
    return this.appSession?.userId > 0;
  }

  getCurrentUserId(): number {
    return this.appSession?.userId ?? 0;
  }

  getFullName() {
    return this.appSession.getFullName();
  }

  // Navigations
  goToHome(fullNavigation: boolean = false) {
    const url = this.getWelcomeScreenURL();
    if(fullNavigation) {
      window.location.href = url;
    } else {
      this.router.navigate([url]);
    }
  }

  getWelcomeScreenURL(): string {
    if(this.hasOnePermissionGranted(['Companies.Read', 'Companies.Write'])) {
      return "/app/companies";
    } else if(this.hasOnePermissionGranted(['User_Companies.Read'])) {
      return "/app/user-companies";
    } else if(this.hasOnePermissionGranted(['Pages.Tenants'])) {
      return "/app/tenants";
    } else {
      return "/app/home";
    }
  }

  goToLogin() {
    this.router.navigate(['/account/login']);
  }

  normalizeString(str: string): string {
    if (str == undefined) return '';
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
  }

  getLanguageFromCookie(): string {
    const cookieName = 'Abp.Localization.CultureName';
    const cookies = document.cookie.split(';');

    for (let cookie of cookies) {
      const [name, value] = cookie.split('=');

      if (name.trim() === cookieName) {
        return value;
      }
    }

    return 'es';
  }

  getCurrentUserRelatedCompanyIds(): number[] {
    var companyIds = localStorage.getItem('companyids');
    if(companyIds) {
      return JSON.parse(companyIds);
    }
  }

  sanitizeText(text: string): string {
    if (!text) {
      return text;
    }

    const cleanedText = text
      .replace(/[\r\n\t]+/g, ' ')
      .replace(/\s+/g, ' ');

    return cleanedText.trim();
  }
}
