import { Injectable, OnInit } from '@angular/core';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ModalStatusService } from './modal-status.service';

@Injectable({
  providedIn: 'root'
})
export class CustomModalService {

  constructor(private _modalService: BsModalService,
    private _modalStatusService: ModalStatusService) {}

  show<T>(content: any, config?: ModalOptions<T>, modalId: string = undefined): BsModalRef<T> {
    this.forceShowModalBackdrop();

    if(modalId != undefined) {
      // Let's find if we already have this modal created and the status
      // is minimized
      let status = this._modalStatusService.getMinimizedStatus(modalId);

      // Initialize the status if we don't have it yet, with not
      // minimized (minimized = false), since we are showing the modal.
      status = status == null || status == undefined ? false : status;
      this._modalStatusService.setMinimizedStatus(modalId, status);

      // If the modal existed and was minimized (true), we opened changing the status
      // instead of creating a new one.
      if(status) {
        return null;
      }
    }

    // Create a modal by calling the original show method with the provided
    // content and config.
    return this._modalService.show(content, config);
  }

  forceShowModalBackdrop() {
    const backdrop = document.querySelector('.modal-backdrop');
    if (backdrop) {
      backdrop.classList.remove('d-none');
      backdrop.classList.add('show');
    }
  }
}

