import { TenantAvailabilityState } from '@shared/service-proxies/service-proxies';

export class AppTenantAvailabilityState {
  static Available: number = TenantAvailabilityState._1;
  static InActive: number = TenantAvailabilityState._2;
  static NotFound: number = TenantAvailabilityState._3;
}

export enum UiThemeEnum {
  Green = 0,
  Blue = 1,
  Custom = 2
}

export enum OrderDirectionEnum {
  Ascending = 0,
  Descending = 1
}

export enum ContactPreferencesEnum {
  MainPhoneNumber = 0,
  AlternatePhoneNumber = 1,
  EmailAddress = 2
}

export enum CompanyRoleEnum {
  DIR = 0,
  ACC = 1,
  CON = 2,
  VIN = 3,
  BF = 4,
  ATT = 5
}

export enum FieldTypeEnum {
  Undefined = 0,
  String = 1,
  Int = 2,
  Date = 3,
  Decimal = 4,
}

export enum FieldComparatorEnum {
  Undefined = 0,
  Equals = 1,
  Contains = 2,
  BiggerThan = 3,
  SmallerThan = 4
}

export enum NotificationTypeEnum {
  Undefined = 'Undefined',
  ResetPassword = 'ResetPassword',
  CompanyChange = 'CompanyChange',
  UserSuggestedCompanyChanges = 'UserSuggestedCompanyChanges',
  AdminResolutionSuggestedCompanyChanges = 'AdminResolutionSuggestedCompanyChanges',
  CreateManager = 'CreateManager',
  AssignedNaturalPerson = 'AssignedNaturalPerson',
  UserSuggestedJuridicalPersonChanges = 'UserSuggestedJuridicalPersonChanges',
  UserSuggestedNaturalPersonChanges = 'UserSuggestedNaturalPersonChanges',
  AdminResolutionSuggestedJuridicalPersonChanges = 'AdminResolutionSuggestedJuridicalPersonChanges',
  AdminResolutionSuggestedNaturalPersonChanges = 'AdminResolutionSuggestedNaturalPersonChanges',
  CreateExternalUser = 'CreateExternalUser'
}

export enum LibraryConfigurationKindEnum {
  Undefined = undefined,
  Company = 1,
  JuridicalPerson = 2,
  NaturalPerson = 3
}

export enum LibraryConfigurationFileTypeEnum {
  Undefined = undefined,
  Directory = 1,
  File = 2,
  Shortcut = 3,
  TpShortcut = 4
}

export enum LibraryConfigurationRoleEnum
{
    Undefined = undefined,
    DIR = 1,
    ACC = 2,
    CON = 3,
    VIN= 4,
    RPJ= 5,
    BF= 6,
    ATT=7,
}

export enum LibraryDialogCreatorType{
  Undefined = undefined,
  TenantSettingsCreation = 1,
  LibraryCreation = 2, 
}

export enum RequestStatusEnum {
  New = 0,
  Approved = 1,
  Rejected = 2,
  Error = 3
}

export enum LibrarySectionListEnum {
  CompanyList = "CompanyList",
  CompanyStructureView = "CompanyStructureView",
  NaturalPersonList = "NaturalPersonList",
  NaturalPersonStructureView = "NaturalPersonStructureView",
  JuridicalPersonList = "JuridicalPersonList",
  JuridicalPersonStructureView = "JuridicalPersonStructureView",
}

export enum LibraryFilterSignalEnum {
  NoTiedToCompany = "NoTiedToCompany",
  TiedToCompany = "TiedToCompany"
}

export enum ReadUnreadEnum {
  Unread = 'Unread',
  Read = 'Read'
}

export enum CompanyTypeEnum {
  SA = 'SA',
  SAS = 'SAS',
  SRL = 'SRL.',
  UNI = 'UNI',
  BVI = 'BVI'
}

export enum ShareTypeEnum {
  NOM = 'NOM',
  POR = 'POR',
  ESC = 'ESC'
}