import { Injectable } from "@angular/core";

@Injectable()
export class TablesHelper {

  constructor() {

  }

  getColumnCssClass(columnIndex: number, columnCssClasses: string[]): string {
    if(columnCssClasses.length <= columnIndex) { return ""; }

    return columnCssClasses[columnIndex];
  }
}
