import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';

import * as ApiServiceProxies from './service-proxies';
import { LoadingInterceptor } from '@shared/interceptors/loading.interceptor';

@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.AuditLogServiceProxy,
        ApiServiceProxies.JuridicalPersonServiceProxy,
        ApiServiceProxies.NaturalPersonServiceProxy,
        ApiServiceProxies.CompanyServiceProxy,
        ApiServiceProxies.CompanyRoleServiceProxy,
        ApiServiceProxies.DocumentTypeServiceProxy,
        ApiServiceProxies.NationalityServiceProxy,
        ApiServiceProxies.CountryServiceProxy,
        ApiServiceProxies.OccupationServiceProxy,
        ApiServiceProxies.MaritalStatusServiceProxy,
        ApiServiceProxies.NuptialsServiceProxy,
        ApiServiceProxies.MarriageRegimeServiceProxy,
        ApiServiceProxies.LocationServiceProxy,
        ApiServiceProxies.CompanyTypeServiceProxy,
        ApiServiceProxies.CurrencyServiceProxy,
        ApiServiceProxies.FunctionalLinkServiceProxy,
        ApiServiceProxies.HealthInsuranceServiceProxy,
        ApiServiceProxies.AttorneyInFactTypeServiceProxy,
        ApiServiceProxies.PositionServiceProxy,
        ApiServiceProxies.ShareTypeServiceProxy,
        ApiServiceProxies.WorkActivityServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.UserNotificationSettingServiceProxy,
        ApiServiceProxies.LibraryServiceProxy,
        ApiServiceProxies.UserLibraryServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }
    ]
})
export class ServiceProxyModule { }
