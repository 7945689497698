import { BehaviorSubject } from 'rxjs';
import { BreadcrumbItem } from '@shared/classes/breadcrumb-item';

export class BreadcrumbsService {
  private breadcrumbItemsProperty: BreadcrumbItem[] = [];
  breadcrumbItems: BehaviorSubject<BreadcrumbItem[]>;

  constructor() {
    this.breadcrumbItems = new BehaviorSubject(this.breadcrumbItemsProperty);
  }

  setNewBreadcrumbsItems(newValue: BreadcrumbItem[]) {
    this.breadcrumbItems.next(newValue);
  }
}