import { Attribute, Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[equalOrLowerValue][ngModel],[equalOrLowerValue][formControl]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EqualOrLowerValueValidator),
      multi: true
    }
  ]
})
export class EqualOrLowerValueValidator implements Validator {
  constructor(@Attribute('equalOrLowerValue') public value: number) {}

  validate(control: AbstractControl): ValidationErrors | null {
    const currentValueControl = control;
    const valueControl = control.root.get(this.value.toString());

    if (!currentValueControl || !valueControl) {
      return null;
    }

    const currentValue = Number.parseFloat(currentValueControl.value);
    const value = Number.parseFloat(valueControl.value);

    if (currentValue && value && currentValue > value) {
      return { equalOrLowerValue: true };
    }

    return null;
  }
}
