import { Component, ElementRef, HostListener, Injector, OnInit, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/app-component-base';
import { AppAuthService } from '@shared/auth/app-auth.service';
import { NotificationServiceProxy } from '@shared/service-proxies/service-proxies';
import { NotificationCountUpdatedService } from "@shared/helpers/notification-count-updated.service";
import { PopoverDirective } from 'ngx-bootstrap/popover';


@Component({
  selector: 'app-right-menu',
  templateUrl: './right-menu.component.html',
  styleUrls: ['./right-menu.component.css']
})
export class RightMenuComponent extends AppComponentBase implements OnInit {
  numberNotifications:  number = 0;
  notificationCountUpdatedService: NotificationCountUpdatedService;
  @ViewChild('popTemplate', { static: true }) popTemplate;
  @ViewChild('calendarPopover', { static: false }) calendarPopover: PopoverDirective;
  @ViewChild('notificationPopover', { static: false }) notificationPopover: PopoverDirective;
  @ViewChild('notificationIcon', { static: false }) notificationIcon: ElementRef;

  isPopoverOpen = false;

  constructor(injector: Injector, 
    private _authService: AppAuthService,
    private _notificationService: NotificationServiceProxy
  ) { 
    super(injector);
    this.notificationCountUpdatedService = injector.get(NotificationCountUpdatedService);
  }

  ngOnInit() {
    this.notificationCountUpdatedService.notification.subscribe((x) => {
      this._notificationService.getUnreadNotificationsCount()
      .subscribe((result: number) => {
        this.numberNotifications = result;
      });
    });
  }

  logout(): void {
    abp.message.confirm(
      this.l('AreYouSureWantToLogout'),
      undefined,
      (result: boolean) => {
          if (result) {
            this._authService.logout();
          }
      }
    );
  }

  redirectUserConfiguration() {
    this.router.navigate(['/app/settings/user-settings'])
  }

  navigateToComponent(): Promise<boolean> {
    return this.router.navigateByUrl('/app/user-diary');
  }

  togglePopover() {
    this.isPopoverOpen = !this.isPopoverOpen;
    if (this.isPopoverOpen) {
      this.navigateToComponent().then(() => {
        setTimeout(() => {
          this.notificationPopover.show();
        }, 1000); 
      });
    } else {
      this.notificationPopover.hide();
    }
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const targetElement = event.target as HTMLElement;
    if (this.notificationIcon) {
      const clickedInsideIcon = this.notificationIcon.nativeElement.contains(targetElement);
      const popoverContainer = document.querySelector('.popover.show');
  
      if (!clickedInsideIcon && popoverContainer && !popoverContainer.contains(targetElement)) {
        this.notificationPopover.hide();
        this.isPopoverOpen = false;
      }
    }
  }
}
