import { Injector, Pipe, PipeTransform } from '@angular/core';
import { PersonCompanyRoleDataDto as ItemDto } from '@shared/service-proxies/service-proxies';
import { AppComponentBase } from '@shared/app-component-base';

@Pipe({
  name: 'personPosition',
  pure: false
})
export class PersonPositionPipe extends AppComponentBase implements PipeTransform {

    constructor(injector: Injector) {
        super(injector);
    }

    transform(item: ItemDto): string {
        return item.position != null ? this.l(item.position.localizationKeyName) : '-';
    }
}